export const WORDS = [
'tõene',
'nägus',
'toodu',
'jalgu',
'tatar',
'eetma',
'ujuma',
'ukaas',
'pisar',
'siuke',
'sootu',
'pimss',
'kihvt',
'fliis',
'väike',
'õieti',
'kiigu',
'krabi',
'turbo',
'järel',
'sünna',
'sootu',
'pimss',
'kihvt',
'fliis',
'väike',
'õieti',
'kiigu',
'pikap',
'servi',
'salat',
'tihke',
'lobri',
'soine',
'topis',
'lõgin',
'heaks',
'koola',
'kombo',
'plank',
'grimm',
'kõlav',
'hõlve',
'näima',
'peagu',
'arvel',
'askus',
'krabi',
'turbo',
'järel',
'sünna',
'sootu',
'pimss',
'kihvt',
'fliis',
'koola',
'kombo',
'kümme',
'rosin',
'depoo',
'nõtku',
'fliis',
'plank',
'grimm',
'kõlav',
'hõlve',
'näima',
'peagu',
'arvel',
'aitäh',
'hoius',
'kohal',
'grimm',
'lodev',
'viltu',
'kupee',
'jubin',
'kaine',
'köide',
'eramu',
'diiva',
'greip',
'blond',
'nitro',
'säbru',
'torge',
'ravim',
'armas',
'uudis',
'sobiv',
'täika',
'teema',
'kants',
'pomin',
'redis',
'kirju',
'sabin',
'islam',
'dogma',
'vigur',
'veniv',
'mõjus',
'lähem',
'aurik',
'pinal',
'sõsar',
'alias',
'vudin',
'julge',
'särts',
'blogi',
'kodar',
'müüma',
'efekt',
'jaoks',
'kanuu',
'põlis',
'vaevu',
'jalas',
'ägama',
'terve',
'skeem',
'haige',
'apnoe',
'timpu',
'lõdve',
'läige',
'kohin',
'kirme',
'matma',
'tumba',
'vidus',
'värin',
'malev',
'pesto',
'komme',
'poeet',
'frukt',
'tugim',
'häire',
'tunne',
'valiv',
'algis',
'käima',
'uhkus',
'räbal',
'pidur',
'pagun',
'piits',
'põdur',
'saaga',
'õiend',
'mammi',
'toide',
'vägev',
'ladum',
'selge',
'õline',
'lõdin',
'panda',
'edutu',
'põrge',
'slepp',
'oktav',
'ruutu',
'aadel',
'kellu',
'näoli',
'eriti',
'astro',
'murre',
'tenor',
'ergas',
'kloor',
'hepta',
'trump',
'juhis',
'stamp',
'meene',
'võide',
'derbi',
'sagin',
'vilin',
'nimel',
'nurgi',
'pisim',
'praam',
'amort',
'siiam',
'parem',
'cover',
'sinep',
'ürgne',
'lehis',
'lähim',
'tehas',
'madam',
'mujal',
'norin',
'tatar',
'embus',
'lähte',
'naine',
'peitu',
'pehmo',
'lirts',
'tehtu',
'vanur',
'katik',
'sutsu',
'kõige',
'evima',
'raibe',
'sulam',
'tuksi',
'tütar',
'kaste',
'kraal',
'müdin',
'pobin',
'lause',
'varss',
'krõps',
'kamps',
'rohke',
'staap',
'purts',
'sünge',
'pitsa',
'väeti',
'reuma',
'süüdi',
'targu',
'stend',
'orund',
'purju',
'pruun',
'rober',
'väide',
'liiga',
'saldo',
'saiga',
'sihin',
'reede',
'veduk',
'ormus',
'karri',
'eliit',
'sääst',
'tõhus',
'nägus',
'kints',
'piire',
'palat',
'klopp',
'ajuti',
'plika',
'tõmmu',
'kruup',
'ehmes',
'ujula',
'norra',
'kalbe',
'kroop',
'duaal',
'kolin',
'volle',
'jääma',
'korin',
'press',
'parka',
'kohta',
'pugem',
'klõmm',
'ruttu',
'lahti',
'rõhud',
'uinak',
'bager',
'ruske',
'kooma',
'hepik',
'imama',
'hõõre',
'lähme',
'tsehh',
'samet',
'suisa',
'panus',
'rudin',
'väärt',
'esine',
'eeben',
'varas',
'kesse',
'habin',
'nääts',
'raksu',
'harta',
'katus',
'poppi',
'mikro',
'pläma',
'isver',
'kamin',
'uusik',
'teeta',
'allee',
'anood',
'grupp',
'kahar',
'paras',
'groot',
'priim',
'ankur',
'mäger',
'kiisu',
'vestu',
'klahv',
'ääris',
'ajutu',
'neuro',
'orden',
'hüpak',
'kelme',
'tango',
'käpik',
'traav',
'lingu',
'loend',
'firma',
'uljas',
'dingo',
'põlev',
'viija',
'hoome',
'manul',
'kidev',
'õgima',
'petma',
'üheli',
'kurin',
'vuhin',
'tõuti',
'marru',
'menuk',
'pleed',
'pobul',
'kärss',
'prikk',
'ahnur',
'ühtne',
'käbar',
'tulem',
'makro',
'graaf',
'pähik',
'puhev',
'magun',
'asula',
'lõõts',
'aroom',
'hihii',
'masin',
'igati',
'jonks',
'klirr',
'töötu',
'tohik',
'õhend',
'pugin',
'sushi',
'resto',
'agent',
'sihku',
'pampa',
'urgas',
'värss',
'loodu',
'parsa',
'sinna',
'ebard',
'treng',
'kuhil',
'assam',
'krohv',
'värav',
'kooda',
'kevad',
'sööve',
'solks',
'oskus',
'vastu',
'meiud',
'laube',
'äpard',
'kuine',
'seier',
'puhta',
'seega',
'suige',
'panev',
'tuber',
'lemma',
'draiv',
'ahhaa',
'tosin',
'himur',
'ladur',
'denim',
'kelts',
'gooti',
'heksa',
'kupar',
'hambu',
'tihti',
'pisem',
'kauri',
'kriik',
'vihik',
'kobra',
'sepis',
'termo',
'karts',
'kisma',
'umami',
'argsi',
'üleni',
'viirg',
'marsa',
'amööb',
'kõvik',
'taiga',
'sõnum',
'radar',
'ehtne',
'vaart',
'maoli',
'taies',
'nutma',
'kabli',
'juura',
'kabin',
'keere',
'trupp',
'rahke',
'ruloo',
'lible',
'põmin',
'klõks',
'kemps',
'hõige',
'jätma',
'morss',
'asend',
'võrra',
'kramm',
'olema',
'isune',
'saade',
'lähen',
'polut',
'keski',
'kipra',
'kirst',
'ripse',
'beebi',
'pulss',
'vanik',
'isand',
'korra',
'diees',
'jupik',
'lemme',
'ütlev',
'hinne',
'vabin',
'hevea',
'marli',
'piirg',
'ihuma',
'suura',
'sinod',
'nubul',
'villa',
'proge',
'rüsin',
'vihur',
'pikka',
'klops',
'karus',
'võrde',
'graal',
'tupik',
'ujuma',
'igand',
'lasum',
'kulon',
'tätar',
'sumbe',
'meeli',
'kätis',
'valus',
'flopi',
'kõlks',
'luule',
'annus',
'tulup',
'krahh',
'krunt',
'lasso',
'karje',
'kille',
'apama',
'otsus',
'pants',
'lohva',
'võlts',
'kööme',
'nõgus',
'dramm',
'irama',
'umbes',
'äsama',
'sorgo',
'poolt',
'passe',
'vegan',
'kütja',
'kraam',
'liisk',
'idand',
'puute',
'poort',
'nagli',
'tinna',
'email',
'jaala',
'ablas',
'nunnu',
'broom',
'oraal',
'kanna',
'krüpt',
'õilis',
'vanus',
'larhv',
'mõõde',
'sisin',
'tasuv',
'hilja',
'pihta',
'narta',
'toodu',
'kanäe',
'küsiv',
'trukk',
'reaal',
'siuks',
'päits',
'prior',
'üleöö',
'püsik',
'almus',
'maine',
'mamps',
'lõuna',
'morse',
'nilbe',
'leedi',
'tõutu',
'tanka',
'tõuse',
'oskar',
'krööm',
'mulla',
'sosin',
'eeter',
'kaupa',
'muude',
'õhuke',
'sular',
'iidne',
'kääks',
'viile',
'kärts',
'aster',
'taser',
'kanne',
'kores',
'gnoom',
'steik',
'vaist',
'lahve',
'kruuk',
'ioota',
'oomen',
'kasin',
'valem',
'puhur',
'kähin',
'tutti',
'kohma',
'stopp',
'lesgi',
'lõust',
'suaav',
'mölin',
'varis',
'kumin',
'sädin',
'asuja',
'vähik',
'drink',
'eksam',
'kirbe',
'bande',
'ilves',
'jooga',
'rögin',
'frakk',
'puhke',
'tamne',
'aniis',
'öeldu',
'märss',
'edasi',
'traat',
'nämma',
'poeem',
'ootus',
'tamka',
'kuune',
'viiks',
'tasku',
'pomel',
'kudin',
'hirne',
'kalle',
'mamma',
'kobar',
'proto',
'mugav',
'maral',
'täpne',
'lahus',
'sohva',
'arutu',
'lõoke',
'krõks',
'trass',
'silks',
'lavat',
'nivoo',
'tempo',
'näppe',
'klikk',
'konks',
'fjord',
'moone',
'proff',
'nurin',
'toote',
'kajak',
'hausa',
'kamar',
'kebab',
'kugin',
'sumdi',
'jalgu',
'rumal',
'siuts',
'keham',
'kätte',
'taine',
'kruvi',
'suude',
'purin',
'tvist',
'parts',
'hiile',
'lapik',
'aarja',
'ports',
'kuvar',
'sitar',
'jamps',
'irvik',
'piste',
'kissi',
'argus',
'rogin',
'püsti',
'tõrje',
'hilis',
'nõges',
'lõõsk',
'kogus',
'piiks',
'riive',
'kuuld',
'ligem',
'pahem',
'õitse',
'õppus',
'küüru',
'ratse',
'hulka',
'sööte',
'apeks',
'vanem',
'mossi',
'viisi',
'turts',
'nähtu',
'torso',
'trits',
'seong',
'levik',
'enoki',
'vänge',
'volks',
'joove',
'kulum',
'rabav',
'kirje',
'kvark',
'kaudu',
'pusta',
'krobe',
'pisut',
'sigar',
'päike',
'müüon',
'raund',
'skiff',
'ausus',
'niisk',
'duett',
'lakei',
'ragin',
'lukku',
'käsil',
'kiiss',
'vikat',
'õõtse',
'kirts',
'köber',
'henna',
'üheti',
'tiree',
'öösel',
'läbim',
'ahing',
'üldse',
'vrapp',
'pihid',
'kujur',
'poees',
'lööma',
'gripp',
'sõtse',
'kolks',
'pesur',
'trumm',
'maotu',
'eosla',
'major',
'pärit',
'bruto',
'kängu',
'slaid',
'helde',
'õunik',
'isutu',
'aduma',
'äärde',
'tirts',
'avaja',
'rippu',
'laika',
'kaape',
'laser',
'armee',
'nigin',
'vanne',
'kraan',
'müsli',
'kompu',
'pisik',
'ivama',
'õuest',
'vagur',
'opaak',
'põrin',
'miilu',
'etnos',
'podin',
'õduma',
'kolla',
'tekst',
'kleit',
'tibla',
'kiasm',
'ilgus',
'nojaa',
'manus',
'täbar',
'vihin',
'komps',
'põime',
'druus',
'säpru',
'mango',
'lõhis',
'staar',
'risla',
'amper',
'haake',
'kälis',
'naber',
'hajus',
'vürts',
'tuiam',
'eveen',
'rauge',
'kramp',
'kuhik',
'rajav',
'särav',
'plüüs',
'juhul',
'umbus',
'snäkk',
'soige',
'sisse',
'sidur',
'parim',
'padin',
'posin',
'välge',
'äitse',
'peale',
'kalge',
'kähku',
'aetis',
'kriit',
'audio',
'skoor',
'salsa',
'sulks',
'pärus',
'tuksu',
'rakne',
'kaust',
'lambu',
'surve',
'uraan',
'gorgo',
'toppe',
'judin',
'hüvis',
]
