export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['Tubli!', 'Äge!', 'Hästi!']
export const GAME_COPIED_MESSAGE = 'Sõnarada kopeeritud'

export const NOT_ENOUGH_LETTERS_MESSAGE = 'Liiga vähe tähti'
export const WORD_NOT_FOUND_MESSAGE = 'Sõna ei leitud'
export const HARD_MODE_ALERT_MESSAGE =
  'Raske režiimi saab sisse lülitada ainult mängu alguses!'
export const HARD_MODE_DESCRIPTION =
  'Kõiki vihjeid tuleb kasutada järgmistes pakkumistes'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Suurem värvide erinevus'

export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Sõna oli: ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Tähte ${guess} peab kasutama kohal ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Pakkumine peab sisaldama ${letter}`

export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Delete'
export const STATISTICS_TITLE = 'Statistika'
export const GUESS_DISTRIBUTION_TEXT = 'Pakkumiste jaotus'
export const NEW_WORD_TEXT = 'Uus sõna'
export const SHARE_TEXT = 'Jaga'
export const TOTAL_TRIES_TEXT = 'Mängitud'
export const SUCCESS_RATE_TEXT = 'Võidu %'
export const CURRENT_STREAK_TEXT = 'Praegune seeria'
export const BEST_STREAK_TEXT = 'Parim seeria'
