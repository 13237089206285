import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Kuidas mängida?" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Iga päev üks sõna. Sõna arvamiseks on 6 katset.
        Peale igat katset näitab kasti värv kui lähedal oli pakutud sõna.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="P"
          status="correct"
        />
        <Cell value="A" />
        <Cell value="L" />
        <Cell value="U" />
        <Cell value="N" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Täht P on selles sõnas ja ka õiges kohas.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="K" />
        <Cell value="Õ" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="R"
          status="present"
        />
        <Cell value="T" />
        <Cell value="S" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Täht R on selles sõnas, kuid vales kohas.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="K" />
        <Cell value="U" />
        <Cell isRevealing={true} isCompleted={true} value="D" status="absent" />
        <Cell value="U" />
        <Cell value="M" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Täht D ei ole selles sõnas.
      </p>

      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
        See on avatud lähtekoodiga sõnaarvamise mäng -{' '}
        <a
          href="https://github.com/LKarel/word-guessing-game"
          className="underline font-bold"
        >
          vaata koodi siit
        </a>{' '}
      </p>
    </BaseModal>
  )
}
