export const VALID_GUESSES = [
'aadam',
'aadel',
'aader',
'aaker',
'aamen',
'aaria',
'aarja',
'aasta',
'aažio',
'aatom',
'abitu',
'ablas',
'abort',
'adjöö',
'adrik',
'aduma',
'aedik',
'aegne',
'aegus',
'aetis',
'afeel',
'afekt',
'afiin',
'afiks',
'afišš',
'afäär',
'agaam',
'agaav',
'agens',
'ahend',
'ahhaa',
'ahing',
'ahnur',
'ahnus',
'ahter',
'ahtma',
'ahtus',
'ahven',
'aiand',
'aidaa',
'aijeh',
'aimus',
'aines',
'ainik',
'ainus',
'aitäh',
'ajaja',
'ajama',
'ajane',
'ajati',
'ajatu',
'ajend',
'ajuma',
'ajuti',
'ajutu',
'akord',
'aktus',
'akuut',
'akõnn',
'alaan',
'alale',
'alane',
'alang',
'alarm',
'alasi',
'alati',
'alatu',
'album',
'alged',
'algis',
'algne',
'algul',
'algus',
'alias',
'alibi',
'allee',
'alles',
'alluv',
'almus',
'altar',
'altee',
'alune',
'alvar',
'ambra',
'ambur',
'amiid',
'amiin',
'ammuu',
'amokk',
'amort',
'ampel',
'amper',
'amööb',
'anaal',
'andam',
'andev',
'andma',
'andur',
'anemo',
'angel',
'aniis',
'anime',
'ankur',
'annak',
'annus',
'anood',
'antud',
'anuma',
'apama',
'apatš',
'apeks',
'aplus',
'apnoe',
'apsat',
'araat',
'aralt',
'arapp',
'areen',
'arend',
'areng',
'argne',
'argoo',
'argsi',
'argus',
'armas',
'armee',
'aroom',
'arrak',
'arsis',
'arste',
'arter',
'arutu',
'arvel',
'asend',
'asima',
'asine',
'asjur',
'askus',
'aspar',
'assam',
'astak',
'astel',
'aster',
'astma',
'astro',
'asuja',
'asula',
'asuma',
'asund',
'asuur',
'asüül',
'ašuug',
'ažuur',
'atakk',
'atlas',
'auasi',
'audio',
'audit',
'augur',
'aukas',
'aukus',
'aurik',
'ausus',
'autor',
'auvis',
'avaar',
'avaja',
'avali',
'avama',
'avang',
'avaus',
'avaõu',
'avers',
'aviis',
'baaba',
'bager',
'baika',
'bakid',
'balsa',
'bande',
'bantu',
'batik',
'beebi',
'beeta',
'beibe',
'bemar',
'besee',
'bidee',
'bingo',
'bissa',
'bjass',
'bjeff',
'blogi',
'blokk',
'blond',
'bluff',
'bluus',
'bläkk',
'bongo',
'bonne',
'boora',
'boson',
'bossa',
'botas',
'botik',
'brand',
'breik',
'bridž',
'briis',
'bronh',
'broom',
'bruto',
'bränd',
'bufee',
'bulla',
'burka',
'burks',
'bursa',
'büroo',
'circa',
'cosec',
'cover',
'crack',
'daina',
'deegu',
'deism',
'dekra',
'delta',
'denga',
'denge',
'denim',
'depoo',
'derbi',
'derma',
'detsi',
'devon',
'diees',
'dieet',
'diele',
'diiva',
'dinas',
'dinee',
'dingo',
'diood',
'disko',
'djakk',
'dogma',
'doina',
'doira',
'dolce',
'dolma',
'domra',
'donja',
'donna',
'doodž',
'draag',
'drahm',
'draiv',
'dramm',
'drapp',
'dreen',
'drink',
'droog',
'droom',
'droon',
'druus',
'dzott',
'džaiv',
'džaul',
'džemm',
'džiig',
'džiip',
'džoki',
'džuut',
'džäss',
'duaal',
'duool',
'duuma',
'dändi',
'eakas',
'eales',
'ebard',
'ebola',
'edasi',
'edikt',
'edutu',
'edvik',
'eeben',
'Eeden',
'eelik',
'eelis',
'eelne',
'eemal',
'eemus',
'eepik',
'eepos',
'eesel',
'eeter',
'eetik',
'eetma',
'eetos',
'eetsi',
'efeeb',
'efekt',
'egiid',
'ehama',
'ehhee',
'ehkki',
'ehmes',
'ehtne',
'eilne',
'eisik',
'eitus',
'ekker',
'eksam',
'elagu',
'elama',
'elamu',
'eliit',
'eluma',
'elund',
'elutu',
'email',
'emand',
'emane',
'ematu',
'embol',
'embus',
'emiir',
'emoji',
'enese',
'ennak',
'ennem',
'ennäe',
'enoki',
'eosla',
'epohh',
'eramu',
'erand',
'ergas',
'eriti',
'erkus',
'esile',
'esine',
'esiti',
'esmas',
'esmik',
'essee',
'ester',
'etaan',
'etapp',
'eteen',
'etlus',
'etnos',
'ettur',
'etüüd',
'etüül',
'etüün',
'eveen',
'evenk',
'evima',
'farad',
'farss',
'fauna',
'femto',
'feood',
'fetiš',
'fiaal',
'filee',
'finiš',
'firma',
'fjeld',
'fjord',
'flaam',
'flešš',
'fliis',
'flirt',
'floks',
'floor',
'flopi',
'flopp',
'fluor',
'foogt',
'fopaa',
'forte',
'frakk',
'frank',
'frees',
'friik',
'friis',
'frits',
'frukt',
'fusti',
'fuuga',
'gamba',
'gamma',
'gauss',
'geiša',
'geoid',
'gibon',
'ginko',
'gjaur',
'gloss',
'glögi',
'gnoom',
'gooti',
'gorgo',
'graaf',
'graal',
'graan',
'gramm',
'grand',
'grave',
'greif',
'greip',
'grimm',
'gripp',
'grogi',
'grokk',
'groot',
'grupp',
'grüps',
'guašš',
'gusli',
'gvelf',
'gürsa',
'haage',
'haake',
'haard',
'haare',
'habin',
'hadži',
'hahaa',
'haige',
'haiku',
'hajus',
'halal',
'halin',
'hallo',
'hambu',
'hange',
'hanke',
'hansa',
'harev',
'hargu',
'harik',
'harta',
'harva',
'haski',
'hašiš',
'haude',
'hauge',
'hausa',
'heaks',
'hehee',
'heide',
'heite',
'heksa',
'hekto',
'helde',
'helge',
'helin',
'helio',
'henna',
'henri',
'hepik',
'hepta',
'heres',
'heris',
'herts',
'hevea',
'hihih',
'hihii',
'hiile',
'hiilg',
'hijab',
'hilis',
'hilju',
'himur',
'hinde',
'hindu',
'hinne',
'hirne',
'hirss',
'hitse',
'hmjaa',
'hohoo',
'hoiak',
'hoide',
'hoius',
'holps',
'homme',
'homne',
'hoole',
'hoome',
'hoone',
'hooti',
'HTTPS',
'hubin',
'huhuu',
'huige',
'huile',
'huist',
'hukas',
'hukka',
'hulga',
'hulgu',
'hulka',
'hullu',
'humal',
'humin',
'hunta',
'huupi',
'hõige',
'hõise',
'hõisk',
'hõive',
'hõlje',
'hõlve',
'hõõge',
'hõõrd',
'hõõre',
'hägus',
'häire',
'hälbe',
'hälin',
'hälve',
'hämar',
'härik',
'härra',
'härtu',
'hüdra',
'hüdro',
'hügro',
'hüpak',
'hüper',
'hüpik',
'hüvak',
'hüvis',
'hüään',
'hüübe',
'hüüde',
'hüüve',
'ibeer',
'idand',
'igama',
'igand',
'igati',
'igine',
'igrek',
'ihane',
'ihhii',
'ihnur',
'ihnus',
'ihuma',
'iibis',
'iidid',
'iidne',
'iidol',
'iidus',
'iiris',
'iisop',
'ikoon',
'ilane',
'ilgur',
'ilgus',
'ilmik',
'ilmne',
'ilmsi',
'ilutu',
'ilves',
'imaam',
'imago',
'imama',
'imeja',
'imema',
'imidž',
'indie',
'indri',
'inert',
'inetu',
'ingel',
'inter',
'intra',
'inuit',
'invar',
'ioota',
'irama',
'irask',
'irbis',
'irdne',
'iriit',
'irvik',
'isand',
'isane',
'isatu',
'isegi',
'isend',
'isetu',
'islam',
'istik',
'isune',
'isutu',
'isver',
'ivama',
'jaala',
'jabur',
'jagin',
'jagur',
'jagus',
'jaguv',
'jahve',
'jajaa',
'jajah',
'jalam',
'jalas',
'jalgu',
'jalka',
'jalul',
'jalus',
'jamps',
'jamss',
'jaoks',
'jaole',
'jaolt',
'jaoti',
'jenka',
'jidiš',
'jobin',
'jonks',
'joode',
'jooga',
'joogi',
'jooks',
'jooma',
'joove',
'jorin',
'jubin',
'judin',
'jugar',
'juhik',
'juhis',
'juhte',
'juhul',
'juhus',
'julge',
'julla',
'jumal',
'jumbu',
'jumin',
'junga',
'jupak',
'jupik',
'jurta',
'jutas',
'jutik',
'jutis',
'jutti',
'juuli',
'juuni',
'juura',
'juust',
'jõlli',
'jõmin',
'jõmps',
'jõnks',
'jõrin',
'jäide',
'jäine',
'jälil',
'jälle',
'jänes',
'jänki',
'järel',
'järge',
'järgi',
'järsk',
'jätis',
'jätke',
'jätma',
'jääde',
'jääma',
'jääne',
'jäänu',
'jüaan',
'kaabe',
'kaabu',
'kaadi',
'kaame',
'kaane',
'kaape',
'kaaps',
'kaart',
'kaasa',
'kabel',
'kaber',
'kabin',
'kabli',
'kabun',
'kadal',
'kadri',
'kaduv',
'kaebe',
'kaema',
'kaeve',
'kahar',
'kahas',
'kahin',
'kahju',
'kahur',
'kaige',
'kaine',
'kaiss',
'kajak',
'kajur',
'kajut',
'kakao',
'kakar',
'kaksi',
'kalbe',
'kalde',
'kaldu',
'kalev',
'kalge',
'kalju',
'kalla',
'kalle',
'kalli',
'kalme',
'kalor',
'kalts',
'kalur',
'kamal',
'kamar',
'kamee',
'kamin',
'kampo',
'kamps',
'kanal',
'kande',
'kanep',
'kange',
'kanna',
'kanne',
'kants',
'kanuu',
'kanäe',
'kapak',
'kapok',
'karge',
'kargu',
'karje',
'karma',
'karri',
'karst',
'karts',
'karus',
'karva',
'kasin',
'kassa',
'kaste',
'kasuk',
'kasum',
'kasur',
'katel',
'katik',
'katke',
'katma',
'katse',
'katus',
'kaude',
'kaudu',
'kauem',
'kauge',
'kauka',
'kaupa',
'kauri',
'kauss',
'kaust',
'kaval',
'kaver',
'kebab',
'kebja',
'keder',
'kedri',
'keede',
'keedu',
'keegi',
'keeks',
'keeld',
'keeli',
'keema',
'keerd',
'keere',
'keham',
'kehik',
'kellu',
'kelme',
'kelts',
'kemps',
'kenus',
'kerge',
'keris',
'kerke',
'kerss',
'keset',
'kesik',
'keski',
'kesse',
'ketas',
'ketra',
'kevad',
'kevis',
'khaan',
'khaki',
'kiasm',
'kiber',
'kibin',
'kibun',
'kidev',
'kidin',
'kidur',
'kigin',
'kihar',
'kihin',
'kihvt',
'kiiam',
'kiidu',
'kiige',
'kiigu',
'kiiks',
'kiips',
'kiird',
'kiire',
'kiiru',
'kiisk',
'kiiss',
'kiisu',
'kiiva',
'kiivi',
'kikas',
'kikka',
'kikki',
'kilge',
'kilin',
'kilks',
'kille',
'kilts',
'kimar',
'kimps',
'kinge',
'kinni',
'kinoa',
'kints',
'kiosk',
'kipra',
'kirbe',
'kirde',
'kirev',
'kirik',
'kirin',
'kirje',
'kirju',
'kirka',
'kirme',
'kirre',
'kirsa',
'kirss',
'kirst',
'kirts',
'kisma',
'kissi',
'kiuks',
'kiust',
'kivik',
'kivim',
'klaar',
'klade',
'klahv',
'klapp',
'klatš',
'kleep',
'kleit',
'klemm',
'klibu',
'klikk',
'klimp',
'klipp',
'klips',
'klirr',
'klomp',
'kloor',
'klopp',
'klops',
'klošš',
'kloun',
'klubi',
'klump',
'klupp',
'klõks',
'klõmm',
'klõps',
'klähv',
'klüüs',
'knihv',
'kniks',
'kobar',
'kobin',
'kobra',
'kodar',
'koger',
'kogum',
'kogur',
'kogus',
'kohal',
'kohev',
'kohin',
'kohma',
'kohta',
'kohus',
'koiku',
'kokku',
'kokni',
'kolde',
'kolge',
'kolin',
'kolju',
'kolks',
'kolla',
'kolle',
'kolli',
'kolme',
'kombe',
'kombi',
'kombo',
'komet',
'komme',
'kommu',
'kompe',
'komps',
'kompu',
'konar',
'kongo',
'konks',
'konku',
'konto',
'konts',
'kooda',
'koodi',
'kooku',
'koola',
'koold',
'koole',
'kooma',
'koond',
'koone',
'koort',
'koosa',
'koost',
'koosu',
'kopra',
'korda',
'korea',
'kores',
'korgi',
'korin',
'korje',
'korra',
'korse',
'korso',
'korts',
'korup',
'kosmo',
'koste',
'kotik',
'kottu',
'kotus',
'kouss',
'kraal',
'kraam',
'kraap',
'kraav',
'krabi',
'krahh',
'krahv',
'krakk',
'kraks',
'kramm',
'kramp',
'krapp',
'kraps',
'krauh',
'kreek',
'kreem',
'kreis',
'kreml',
'krepp',
'kribe',
'kribu',
'krihv',
'kriik',
'kriim',
'kriis',
'kriit',
'krimi',
'kriuh',
'krobe',
'krohv',
'kroks',
'kroku',
'kronu',
'krook',
'kroom',
'kroop',
'kroov',
'krous',
'krupp',
'krusa',
'kruuk',
'kruup',
'kruus',
'kruvi',
'krõbe',
'krõbi',
'krõks',
'krõps',
'krõõt',
'kräbe',
'kräkk',
'kräsu',
'krööm',
'krüpt',
'ksero',
'kubel',
'kudin',
'kudum',
'kugin',
'kuhik',
'kuhil',
'kuhje',
'kuigi',
'kuine',
'kuive',
'kujur',
'kukal',
'kukil',
'kukku',
'kulak',
'kulin',
'kulla',
'kulon',
'kulpa',
'kulum',
'kumer',
'kumin',
'kummi',
'kunas',
'kunde',
'kunel',
'kupar',
'kupee',
'kupla',
'kuram',
'kurat',
'kurin',
'kursa',
'kurss',
'kusal',
'kuski',
'kutse',
'kutsu',
'kuues',
'kuuik',
'kuuld',
'kuule',
'kuulo',
'kuulu',
'kuune',
'kuusk',
'kuuti',
'kuvar',
'kvark',
'kvart',
'kveen',
'kvoot',
'kõbin',
'kõbus',
'kõder',
'kõhin',
'kõige',
'kõlar',
'kõlav',
'kõlin',
'kõlks',
'kõmdi',
'kõmin',
'kõnts',
'kõrbe',
'kõrge',
'kõrik',
'kõrin',
'kõrne',
'kõrts',
'kõrva',
'kõrve',
'kõrvu',
'kõuts',
'kõver',
'kõvik',
'kõõks',
'käbar',
'kädin',
'käest',
'kägar',
'kägin',
'kähar',
'kähin',
'kähku',
'käija',
'käima',
'käive',
'käkra',
'käkru',
'kälis',
'kängu',
'känts',
'käpik',
'kärbe',
'kärin',
'kärme',
'kärss',
'kärts',
'käsik',
'käsil',
'kätis',
'kätte',
'käula',
'kääks',
'käänd',
'kääne',
'kääri',
'kääts',
'köber',
'kögin',
'köide',
'kööga',
'kööku',
'kööme',
'kübar',
'küber',
'külas',
'külik',
'külla',
'kümme',
'küpse',
'küree',
'kürii',
'küsiv',
'kütis',
'kütja',
'kütke',
'kütma',
'kütte',
'kütus',
'küüru',
'küüsi',
'laama',
'laast',
'laava',
'labak',
'labor',
'labus',
'ladem',
'ladin',
'ladna',
'ladra',
'ladum',
'ladur',
'ladus',
'laeng',
'lagin',
'lagle',
'lahas',
'lahin',
'lahja',
'lahke',
'lahku',
'lahti',
'lahus',
'lahve',
'laidu',
'laika',
'laine',
'laisk',
'laius',
'lakei',
'lalin',
'lamam',
'lambu',
'lamee',
'lange',
'lanne',
'laoti',
'lapik',
'lapul',
'largo',
'larhv',
'larin',
'larts',
'laser',
'laske',
'lasso',
'lastu',
'lasum',
'laube',
'laudi',
'lauge',
'lausa',
'lause',
'lausk',
'laust',
'lauto',
'lavat',
'leebe',
'leede',
'leedi',
'leege',
'leelo',
'leelu',
'leema',
'lehik',
'lehis',
'leier',
'leige',
'leitu',
'leivu',
'lembe',
'lemma',
'lemme',
'lenks',
'lenne',
'lento',
'leost',
'lepik',
'lepis',
'lepne',
'lesbi',
'lesgi',
'letšo',
'levik',
'levis',
'liaan',
'libik',
'lible',
'lidin',
'lidus',
'ligem',
'lihas',
'lihav',
'liide',
'liiga',
'liige',
'liisk',
'liist',
'liisu',
'liite',
'liivr',
'likku',
'lilla',
'limbo',
'limps',
'lingu',
'linik',
'linne',
'linuk',
'lipik',
'lipsi',
'lirin',
'lirts',
'lirva',
'lisna',
'litši',
'littu',
'liuge',
'liusk',
'lobin',
'lobri',
'lodev',
'lodin',
'lodža',
'loend',
'loeng',
'loetu',
'logar',
'login',
'lohin',
'lohva',
'loide',
'loits',
'lojus',
'lokse',
'lommi',
'longu',
'lonks',
'loode',
'looge',
'looja',
'looma',
'loome',
'loori',
'loppi',
'lorin',
'lorri',
'lorts',
'lossi',
'ludin',
'lugem',
'luger',
'lugin',
'luhta',
'luhva',
'luhvt',
'luide',
'luine',
'luise',
'luisk',
'luits',
'lukum',
'lurin',
'lurts',
'luste',
'luues',
'luule',
'luune',
'luure',
'lõbus',
'lõdin',
'lõdve',
'lõgin',
'lõhik',
'lõhis',
'lõhke',
'lõhki',
'lõige',
'lõike',
'lõkad',
'lõmps',
'lõnks',
'lõpma',
'lõpus',
'lõrbe',
'lõrin',
'lõuna',
'lõusk',
'lõust',
'lõõga',
'lõõsk',
'lõõts',
'läbim',
'lähem',
'lähen',
'lähim',
'lähis',
'lähme',
'lähte',
'läide',
'läige',
'läila',
'läinu',
'lämbe',
'lämmi',
'lärin',
'lärts',
'lävis',
'lääge',
'lääpa',
'lääts',
'lömmi',
'lörin',
'lörri',
'lörts',
'lössi',
'löödu',
'lööja',
'lööma',
'lööve',
'lühem',
'lühim',
'lühis',
'lükra',
'lürbe',
'lüüme',
'lüüra',
'maage',
'maaja',
'maani',
'maara',
'maare',
'maast',
'maatu',
'macho',
'madal',
'madam',
'madar',
'madel',
'madin',
'madis',
'magas',
'magma',
'magot',
'magun',
'magus',
'mahti',
'maias',
'maika',
'maine',
'maius',
'major',
'makja',
'makra',
'makro',
'makse',
'maksi',
'malbe',
'malev',
'malts',
'mambo',
'mamma',
'mammi',
'mamps',
'manat',
'manga',
'mango',
'manna',
'mansa',
'manul',
'manus',
'maoli',
'maotu',
'maral',
'maran',
'marli',
'marru',
'marsa',
'marss',
'marus',
'masai',
'maser',
'masin',
'matar',
'matke',
'matma',
'matus',
'medal',
'meede',
'meeli',
'meene',
'meere',
'meger',
'mehem',
'mehka',
'meier',
'meiud',
'melon',
'menuk',
'menüü',
'merja',
'mersu',
'meson',
'meteo',
'mihus',
'miilu',
'miisu',
'mikro',
'milgi',
'mille',
'milli',
'minek',
'minev',
'mingi',
'minia',
'minut',
'miska',
'miski',
'missa',
'mitra',
'mitte',
'mnemo',
'mnjaa',
'mnjah',
'mobla',
'modem',
'mogul',
'moira',
'moiva',
'mokas',
'mokka',
'mokša',
'moodi',
'moone',
'morse',
'morsk',
'morss',
'mossi',
'mošee',
'mudel',
'mudil',
'mufti',
'mugav',
'mugul',
'muide',
'muidu',
'muist',
'mujal',
'mulin',
'mulje',
'mulks',
'mulla',
'mulle',
'mullu',
'mumio',
'mumps',
'munel',
'murdu',
'murel',
'murre',
'murus',
'muton',
'muude',
'muusa',
'muuti',
'muuvi',
'mõist',
'mõjur',
'mõjus',
'mõjuv',
'mõmin',
'mõnus',
'mõsik',
'mõtus',
'mõõde',
'mõõdu',
'mõõte',
'mäger',
'mähis',
'märge',
'märss',
'märts',
'märul',
'mätas',
'määre',
'mögin',
'möire',
'mölin',
'mörin',
'mööda',
'müdin',
'mügar',
'mügin',
'mügri',
'mühin',
'müoom',
'mürin',
'mürsk',
'mürts',
'müsli',
'müüja',
'müüma',
'müüon',
'naast',
'naber',
'nabin',
'nacho',
'nafta',
'nagin',
'nagli',
'nahin',
'nahua',
'naima',
'naine',
'najal',
'nakar',
'nanai',
'nandu',
'narin',
'narko',
'narta',
'narts',
'neele',
'neetu',
'nelik',
'nelja',
'nelma',
'nemad',
'neoon',
'nerka',
'neste',
'neuro',
'nidus',
'nigel',
'nigin',
'nihik',
'niide',
'niigi',
'niiks',
'niisk',
'nilbe',
'nimel',
'nimme',
'ninja',
'nirin',
'nitro',
'niuks',
'nivoo',
'nogai',
'nohik',
'nohin',
'nojaa',
'nokik',
'nokis',
'noneh',
'nonoh',
'noova',
'norin',
'norse',
'norss',
'norus',
'nosin',
'notar',
'nublu',
'nubuk',
'nubul',
'nugat',
'nugis',
'nurgi',
'nurin',
'nurja',
'nutma',
'nuuks',
'nõder',
'nõele',
'nõges',
'nõgus',
'nõjas',
'nõmik',
'nõnda',
'nõnge',
'nõres',
'nõtke',
'nõtku',
'nõude',
'nõuka',
'nõutu',
'nädal',
'nägur',
'nägus',
'nähtu',
'näide',
'näima',
'näite',
'nämma',
'näoli',
'näotu',
'näppe',
'nässu',
'näuks',
'nääps',
'nääre',
'nääts',
'nööge',
'nööre',
'obrok',
'odöör',
'ofort',
'ofset',
'ohhoo',
'ohjur',
'ohter',
'ohutu',
'ohver',
'oinas',
'oinik',
'oivik',
'oktav',
'olema',
'olend',
'oleng',
'oliiv',
'olija',
'ollus',
'omama',
'omand',
'omane',
'ometi',
'onkel',
'oober',
'oodus',
'ooker',
'oomen',
'ooper',
'oopus',
'ootel',
'ootus',
'opaak',
'opaal',
'opman',
'optik',
'oraal',
'oranž',
'orden',
'order',
'organ',
'orgia',
'orjus',
'ormus',
'orund',
'osalt',
'ositi',
'oskar',
'oskur',
'oskus',
'osman',
'osoon',
'ostma',
'osund',
'osuti',
'otiit',
'otsak',
'otsas',
'otsus',
'ovaal',
'paari',
'paast',
'paber',
'pabin',
'pabul',
'padin',
'padur',
'paene',
'pagan',
'pagar',
'pagas',
'pagin',
'pagun',
'pahem',
'pahin',
'pahur',
'paiga',
'paige',
'paiku',
'paind',
'paine',
'paise',
'paisk',
'pajuk',
'pakal',
'pakil',
'pakis',
'pakke',
'pakku',
'pakme',
'palat',
'palav',
'palee',
'paleo',
'palge',
'palju',
'palve',
'pampa',
'panda',
'pandu',
'panek',
'panev',
'panna',
'pants',
'panus',
'paras',
'parem',
'parim',
'parin',
'parka',
'parsa',
'parts',
'parun',
'pasha',
'passe',
'pasta',
'pasun',
'peagi',
'peagu',
'peale',
'pealt',
'peast',
'peats',
'peatu',
'peber',
'pedak',
'peerg',
'peeso',
'pehik',
'pehme',
'pehmo',
'peiar',
'peide',
'peied',
'peite',
'peits',
'peitu',
'peksa',
'peled',
'pelgu',
'pelus',
'penne',
'penta',
'peoon',
'pepsi',
'perfo',
'perrä',
'perse',
'pesto',
'pesur',
'petik',
'petis',
'petma',
'pette',
'piaal',
'piano',
'pidal',
'pidav',
'pidev',
'pidur',
'pieso',
'pigem',
'pihid',
'pihik',
'pihta',
'pihus',
'piiga',
'piiks',
'piilu',
'piion',
'piird',
'piire',
'piirg',
'piisk',
'piits',
'pikal',
'pikap',
'pikee',
'pikem',
'pikka',
'pikku',
'pikne',
'pikša',
'pilak',
'pilda',
'pilet',
'pilge',
'pilve',
'pimik',
'pimss',
'pinal',
'pinde',
'pinev',
'pinge',
'pingu',
'pinin',
'pinks',
'pipar',
'pirin',
'pirts',
'pisar',
'pisem',
'pisik',
'pisim',
'pisku',
'piste',
'pisut',
'pitsa',
'piuks',
'plaaž',
'plagu',
'plaks',
'plank',
'plate',
'plats',
'plauh',
'pleed',
'pleek',
'pleht',
'plekk',
'pleku',
'pliin',
'pliit',
'plika',
'pliks',
'plink',
'plokk',
'plomm',
'ploom',
'pluss',
'pluus',
'plõks',
'plõmm',
'pläga',
'pläma',
'plära',
'pläru',
'plöga',
'plüüs',
'pobin',
'pobul',
'podin',
'poeem',
'poees',
'poeet',
'pogri',
'point',
'poise',
'poiss',
'poisu',
'polda',
'polis',
'polje',
'polka',
'polut',
'pomel',
'pomin',
'ponks',
'ponts',
'poodu',
'pooge',
'poole',
'poolt',
'pooma',
'poonu',
'poort',
'poose',
'poppi',
'porin',
'porno',
'porru',
'porss',
'porte',
'porto',
'ports',
'posin',
'potas',
'praak',
'praam',
'pragu',
'praht',
'praks',
'prauh',
'prigu',
'priim',
'priis',
'prikk',
'priks',
'prink',
'prior',
'priuh',
'proff',
'proge',
'promo',
'proov',
'props',
'proto',
'proua',
'pruuk',
'prõks',
'prõmm',
'prääk',
'prügi',
'psalm',
'ptruu',
'pudel',
'puder',
'pudev',
'pudin',
'pugal',
'pugem',
'pugin',
'puhas',
'puhev',
'puhin',
'puhis',
'puhke',
'puhta',
'puhte',
'puhul',
'puhun',
'puhur',
'puige',
'puine',
'puist',
'pujus',
'pulke',
'pumat',
'pumps',
'pungi',
'punik',
'punkt',
'punna',
'punni',
'purin',
'purju',
'purre',
'purse',
'purts',
'pusar',
'pusle',
'pusta',
'puštu',
'putku',
'putuk',
'puude',
'puudu',
'puuks',
'puuma',
'puuna',
'puure',
'puute',
'põder',
'põdur',
'põgus',
'põhik',
'põhis',
'põige',
'põiki',
'põime',
'põlev',
'põlis',
'põmdi',
'põmin',
'põnev',
'põnts',
'põrge',
'põrgu',
'põrin',
'pädev',
'pähik',
'päine',
'päits',
'pänni',
'pänts',
'pärak',
'päral',
'päris',
'pärit',
'pärre',
'pärss',
'pärus',
'pääle',
'päält',
'pääse',
'pääsu',
'pöial',
'pöörd',
'pööre',
'püdal',
'püdel',
'pügal',
'pühak',
'pühis',
'pühke',
'püree',
'pürst',
'püsik',
'püsiv',
'pütik',
'püüne',
'raada',
'raama',
'raand',
'rabak',
'rabav',
'rabin',
'radar',
'radio',
'radža',
'ragbi',
'ragin',
'raguu',
'rahke',
'rahul',
'raibe',
'raisk',
'raius',
'rajav',
'rakis',
'rakku',
'rakne',
'raksu',
'ralli',
'rambe',
'ramul',
'range',
'ranne',
'raske',
'ratas',
'ratsa',
'ratse',
'ratsu',
'raudu',
'rauge',
'raund',
'rauts',
'ravim',
'reaal',
'reast',
'redel',
'redis',
'reede',
'reema',
'reiki',
'reiud',
'reket',
'relee',
'repro',
'resto',
'režii',
'retro',
'rettu',
'reuma',
'revüü',
'riaal',
'ribin',
'rigin',
'rihti',
'riida',
'riise',
'riisk',
'riist',
'riiul',
'riive',
'rikas',
'rikki',
'rikša',
'rinde',
'ringe',
'ringi',
'rinne',
'rinnu',
'ripik',
'rippu',
'ripse',
'risla',
'riste',
'ritta',
'riuge',
'rober',
'robin',
'robot',
'rodeo',
'rogin',
'rohke',
'roisk',
'rondo',
'ronga',
'rooki',
'roome',
'roosa',
'roosk',
'roots',
'rootu',
'rosin',
'rubla',
'rudin',
'rukis',
'ruloo',
'rumal',
'rumba',
'ruske',
'rusuv',
'ruttu',
'ruuge',
'ruutu',
'rõgin',
'rõhke',
'rõhud',
'rõhuv',
'rõlge',
'rõske',
'rõõne',
'rõõsk',
'räbal',
'räbik',
'rägbi',
'rägin',
'räige',
'räits',
'räkit',
'rämps',
'rände',
'ränga',
'ränne',
'rännu',
'räsas',
'rätik',
'rääks',
'rääst',
'rääts',
'rögin',
'rööbe',
'rööge',
'rümin',
'rünne',
'rüsin',
'rüübe',
'saade',
'saaga',
'saage',
'saago',
'saaja',
'saama',
'saast',
'saate',
'saati',
'sabat',
'sabin',
'sadam',
'sadul',
'sagar',
'sagin',
'sahib',
'sahin',
'sahmu',
'sahvt',
'saida',
'saiga',
'saira',
'sajas',
'sakri',
'salat',
'saldo',
'salsa',
'salto',
'salve',
'samas',
'samba',
'sambo',
'samet',
'samsa',
'samss',
'sants',
'saras',
'sassi',
'saund',
'seade',
'sealt',
'seast',
'seatu',
'sebra',
'sedel',
'seder',
'seebu',
'seede',
'seega',
'seeme',
'seest',
'segur',
'sehvt',
'seier',
'seiid',
'seire',
'sekka',
'sekst',
'selfi',
'selge',
'selis',
'selle',
'selva',
'selve',
'senat',
'senna',
'seong',
'sepik',
'sepis',
'servi',
'sette',
'sfäär',
'sibul',
'sidin',
'sidur',
'sidus',
'sigar',
'sigma',
'sigur',
'sihik',
'sihin',
'sihku',
'siiam',
'siire',
'silde',
'silin',
'silks',
'silla',
'sille',
'silur',
'simss',
'sinel',
'sinep',
'sinna',
'sinod',
'sirel',
'sirge',
'sirgu',
'sirin',
'sirts',
'sisal',
'sisim',
'sisin',
'sisse',
'sisus',
'sitar',
'sitke',
'siuks',
'siuts',
'skald',
'skatt',
'skaut',
'skeem',
'sketš',
'skiff',
'skinn',
'skipp',
'skiso',
'skits',
'skoor',
'skraa',
'skunk',
'skäär',
'sküüt',
'slaid',
'slepp',
'slumm',
'slämm',
'släng',
'snoob',
'snäkk',
'sobiv',
'soend',
'soeng',
'soerd',
'sohva',
'soige',
'soiku',
'soine',
'solge',
'solin',
'solks',
'solve',
'sooda',
'sooja',
'soolo',
'sooma',
'sootu',
'sopis',
'sopka',
'sorav',
'sorgo',
'sorgu',
'sorid',
'sorin',
'sorts',
'sorus',
'sosin',
'soust',
'spasm',
'sperm',
'spinn',
'spoon',
'spoor',
'sport',
'sprei',
'spurt',
'spämm',
'staap',
'staar',
'staaž',
'stamp',
'start',
'steel',
'steik',
'stepp',
'stiil',
'stilb',
'stipp',
'stoik',
'stopp',
'stukk',
'suaav',
'sudak',
'suhin',
'suige',
'suine',
'suisa',
'suits',
'sujuv',
'sukel',
'suksu',
'sulam',
'sular',
'sulav',
'sulge',
'sulin',
'sulis',
'sulks',
'sulps',
'sulus',
'sumbe',
'sumdi',
'sumer',
'sumin',
'summa',
'summe',
'sunna',
'supee',
'supel',
'super',
'surin',
'surnu',
'surts',
'suruk',
'surve',
'sushi',
'susin',
'susla',
'sutsu',
'suube',
'suude',
'suula',
'suuli',
'suulu',
'suund',
'suura',
'suusk',
'suvik',
'svamm',
'svert',
'sviit',
'sving',
'sõber',
'sõdur',
'sõele',
'sõkal',
'sõmer',
'sõnum',
'sõsar',
'sõtke',
'sõtse',
'sõtta',
'sõude',
'sõõrd',
'sõõre',
'säbar',
'säbru',
'sädin',
'sähke',
'säile',
'säpru',
'särav',
'särin',
'särme',
'särts',
'säuts',
'säält',
'sääsk',
'sääst',
'söene',
'sööbe',
'sööde',
'sööja',
'sööma',
'sööst',
'sööte',
'sööve',
'sügav',
'sügis',
'sülem',
'sünge',
'süste',
'süžee',
'sütik',
'süvik',
'süvis',
'süüde',
'süüdi',
'süüme',
'süüta',
'süütu',
'šanss',
'šerif',
'šerpa',
'šerri',
'šiiit',
'šlakk',
'šogun',
'šrift',
'zombi',
'žaboo',
'želee',
'žiiro',
'žürii',
'taane',
'taara',
'taarn',
'tabav',
'tabel',
'tagal',
'tahes',
'tahke',
'taibu',
'taide',
'taies',
'taiga',
'taine',
'tajur',
'takka',
'takso',
'talje',
'talum',
'talva',
'tamil',
'tamka',
'tamne',
'tamsa',
'tango',
'tanka',
'tanke',
'tanta',
'tante',
'tants',
'tanum',
'taoti',
'tapja',
'tapma',
'tappa',
'tarbe',
'targu',
'tarne',
'tarre',
'tarve',
'tasem',
'taser',
'tasku',
'tasuv',
'tatar',
'tatik',
'taust',
'teabe',
'teade',
'teave',
'teder',
'teema',
'teene',
'teeta',
'tegev',
'tegum',
'tegur',
'tegus',
'tehas',
'tehis',
'tehno',
'tehte',
'tehtu',
'teine',
'teism',
'teist',
'tekis',
'tekke',
'tekno',
'teksa',
'tekst',
'teler',
'telik',
'tempo',
'tenge',
'tenor',
'teras',
'terav',
'teris',
'termo',
'terts',
'terve',
'tesla',
'tetra',
'tibla',
'tihke',
'tihti',
'tihvt',
'tiide',
'tiine',
'tilde',
'tilge',
'tilin',
'tilke',
'tillu',
'timmi',
'timpu',
'timut',
'tinin',
'tinna',
'tipsi',
'tiree',
'tirel',
'tirin',
'tirts',
'toend',
'toeng',
'tohik',
'tohoh',
'tohoo',
'toide',
'toime',
'toite',
'tolge',
'tomat',
'tondo',
'tongi',
'tonks',
'toode',
'toodu',
'tooga',
'tooja',
'tooma',
'toona',
'toora',
'toore',
'toori',
'toote',
'topis',
'topka',
'toppe',
'torge',
'torik',
'torin',
'torso',
'torts',
'tosin',
'tossi',
'tpruu',
'traav',
'trafo',
'tragi',
'trahv',
'traks',
'trakt',
'tramm',
'trans',
'trapp',
'trekk',
'treng',
'trepp',
'triik',
'triip',
'triiv',
'trikk',
'trimm',
'tripp',
'trips',
'trits',
'tromb',
'troop',
'tropp',
'trops',
'troll',
'truba',
'trukk',
'trumm',
'trump',
'trupp',
'truup',
'träni',
'trääs',
'trükk',
'trümm',
'tsaar',
'tsehh',
'tsink',
'tsipa',
'tsirk',
'tsoon',
'tsura',
'tšehh',
'tšekk',
'tšiia',
'tšikk',
'tšips',
'tšumm',
'tšuud',
'tuber',
'tubin',
'tubli',
'tuder',
'tugev',
'tugim',
'tuhar',
'tuhat',
'tuhin',
'tuhud',
'tuiam',
'tuige',
'tuise',
'tuisk',
'tukat',
'tukse',
'tuksi',
'tuksu',
'tulek',
'tulem',
'tulev',
'tulnu',
'tulup',
'tulus',
'tumba',
'tunne',
'tuntu',
'tupik',
'turba',
'turbo',
'turri',
'turse',
'tursk',
'turts',
'turva',
'turve',
'tušee',
'tutti',
'tuuba',
'tuupi',
'tuutu',
'tviid',
'tvist',
'tõend',
'tõene',
'tõhus',
'tõkis',
'tõlge',
'tõmme',
'tõmmu',
'tõpra',
'tõrge',
'tõrin',
'tõrje',
'tõrve',
'tõste',
'tõttu',
'tõuge',
'tõuse',
'tõuti',
'tõutu',
'täbar',
'tähik',
'tähis',
'täide',
'täika',
'täima',
'täite',
'täius',
'tänav',
'tänna',
'täpes',
'täpik',
'täpne',
'tärge',
'tärin',
'tätar',
'töine',
'tökat',
'tölli',
'törin',
'törts',
'töötu',
'tühik',
'tülin',
'tülka',
'tümin',
'tümps',
'tütar',
'tüves',
'tüvik',
'tüüne',
'tüütu',
'ubima',
'ubrik',
'udehe',
'udima',
'udras',
'udune',
'uhama',
'uhhaa',
'uhhuu',
'uhkus',
'uhmer',
'uhtma',
'uijah',
'uimus',
'uinak',
'uitma',
'ujuja',
'ujula',
'ujuma',
'ukaas',
'ulaan',
'ulama',
'uljas',
'uljur',
'ultra',
'ultus',
'uluma',
'umami',
'umbes',
'umbne',
'umbra',
'umbus',
'ummik',
'unelm',
'unetu',
'ungas',
'ungur',
'unine',
'untsu',
'unuma',
'uppis',
'uraan',
'urama',
'urbne',
'urgas',
'uriin',
'urjah',
'urjeh',
'urjuh',
'usjas',
'ustav',
'usund',
'utiil',
'uudis',
'uudne',
'uudus',
'uuelt',
'uurak',
'uurik',
'uuris',
'uusik',
'uusus',
'uutma',
'vaaba',
'vaade',
'vaadi',
'vaali',
'vaane',
'vaart',
'vabin',
'vadak',
'vader',
'vadin',
'vaene',
'vaevu',
'vagel',
'vagun',
'vagur',
'vagus',
'vahek',
'vahel',
'vaher',
'vahva',
'vaibe',
'vaier',
'vaiki',
'vainu',
'vaist',
'vajak',
'vajuk',
'vakka',
'vakus',
'valem',
'valev',
'valge',
'valgu',
'valik',
'valim',
'valju',
'valla',
'valum',
'valus',
'valve',
'vande',
'vanem',
'vangi',
'vanik',
'vanne',
'vanur',
'vanus',
'vaond',
'varal',
'varas',
'varba',
'varda',
'varem',
'vares',
'varia',
'varik',
'varin',
'varis',
'varje',
'varju',
'varre',
'varss',
'varul',
'vasak',
'vasar',
'vasem',
'vaste',
'vastu',
'vatsk',
'veatu',
'vedad',
'vedel',
'vedik',
'vedru',
'veduk',
'vedur',
'vedus',
'veebr',
'veene',
'veere',
'veerg',
'veetu',
'vegan',
'veidi',
'velme',
'venis',
'veniv',
'venku',
'verev',
'verme',
'verst',
'verte',
'vesik',
'veste',
'vestu',
'vibra',
'vibro',
'vibur',
'video',
'videv',
'vidin',
'vidus',
'vigel',
'vigin',
'vigle',
'vigur',
'vihik',
'vihin',
'vihje',
'vihur',
'viibe',
'viide',
'viies',
'viija',
'viiks',
'viile',
'viima',
'viirg',
'viisa',
'viisi',
'viisk',
'viite',
'viiul',
'viive',
'vikat',
'vilge',
'vilin',
'vilka',
'vilks',
'villa',
'viltu',
'vilus',
'vimka',
'vinge',
'vinin',
'vints',
'virge',
'viril',
'virin',
'virre',
'virts',
'virus',
'virva',
'virve',
'visin',
'viski',
'vissi',
'viudi',
'viuks',
'vivat',
'vlgvn',
'vlogi',
'vobla',
'vodka',
'vogul',
'volks',
'volle',
'voodi',
'voole',
'vorst',
'vrakk',
'vrapp',
'vuaal',
'vudin',
'vuhin',
'vuhti',
'vuhva',
'vulin',
'vulks',
'vurdi',
'vurhv',
'vurin',
'vurle',
'vurts',
'vusin',
'vussi',
'võbin',
'võdin',
'võhik',
'võide',
'võidu',
'võima',
'võime',
'võine',
'võitu',
'võlgu',
'võlur',
'võluv',
'võnge',
'võnke',
'võnks',
'võrde',
'võrge',
'võrik',
'võrin',
'võrra',
'võrre',
'võrse',
'võsik',
'võtja',
'võtma',
'väele',
'väeti',
'vägev',
'vähem',
'vähik',
'vähil',
'vähim',
'väide',
'väike',
'väits',
'välde',
'välge',
'välis',
'välja',
'välje',
'välme',
'vänge',
'vänts',
'värat',
'värav',
'värin',
'värss',
'vääks',
'vääne',
'väärt',
'vööde',
'vöönd',
'vürst',
'vürts',
'õdang',
'õduma',
'õelus',
'õgard',
'õgija',
'õgima',
'õhend',
'õhutu',
'õhvik',
'õieli',
'õiend',
'õieti',
'õigus',
'õilis',
'õisik',
'õitse',
'õland',
'õlgne',
'õline',
'õliti',
'õmbla',
'õngel',
'õnnal',
'õnnar',
'õnnis',
'õping',
'õpitu',
'õppur',
'õppus',
'õrnus',
'õsuma',
'õudne',
'õudus',
'õuest',
'õuhka',
'õunik',
'õõnes',
'õõtse',
'õõvar',
'ägama',
'äitse',
'äkane',
'äkine',
'älves',
'ämber',
'äpard',
'ärati',
'ärgas',
'ärgem',
'ärila',
'ärima',
'ärkel',
'äsama',
'äärde',
'ääres',
'äärik',
'ääris',
'äärne',
'ödeem',
'öelda',
'öeldu',
'ööbik',
'öösel',
'üheli',
'ühend',
'ühene',
'üheti',
'ühine',
'ühing',
'ühtne',
'üksik',
'ükski',
'üksus',
'ülalt',
'ülane',
'ülang',
'ülbik',
'ülbur',
'ülene',
'üleni',
'üleöö',
'üllas',
'üllus',
'ümber',
'ümmik',
'üpris',
'üraja',
'ürama',
'ürask',
'ürgne',
'üsked',
'ütlev',
'ütlus',
'palun',
'agent',
'aldis',
'arest',
'arust',
'britt',
'duell',
'duett',
'eesti',
'eitav',
'flööt',
'fraas',
'hakul',
'hariv',
'hiina',
'hilja',
'hulgi',
'hästi',
'kanti',
'katki',
'kitsi',
'klaas',
'klann',
'klass',
'klots',
'kraad',
'kraan',
'kratt',
'kroon',
'kross',
'krunt',
'kulul',
'kunst',
'külge',
'leedu',
'loodu',
'lukku',
'lõoke',
'mõjul',
'norra',
'nunnu',
'ostja',
'plaan',
'plaat',
'plast',
'plätu',
'poola',
'praad',
'press',
'pross',
'pruun',
'pruut',
'pulss',
'putka',
'päike',
'püsti',
'risti',
'surun',
'saksa',
'segav',
'selts',
'siduv',
'siuke',
'soome',
'stend',
'sünna',
'taani',
'traat',
'trall',
'trass',
'trell',
'trend',
'trenn',
'troon',
'tross',
'trots',
'tuttu',
'uisud',
'valiv',
'veski',
'võlts',
'õhuke',
'ülbus',
'üldse'
]
